<template>
  <div>
    <ConsentScreen
      v-if="loggedIn"
      :valid-fed-id="validFederatedId"
      :federated-id="federatedId"
    />
    <Login
      v-else
      :valid-fed-id="validFederatedId"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { isUserLoggedIn } from '@/auth/utils'
import Login from './Login.vue'
import ConsentScreen from './consent/ConsentScreen.vue'

export default {
  components: {
    Login,
    ConsentScreen,
  },
  data() {
    return {
      loggedIn: null,
      validFederatedId: null,
      federatedId: null,
    }
  },
  watch: {
    $route(to, from) {
      this.checkFederatedId()
      this.loggedIn = isUserLoggedIn()
    },
  },
  created() {
    const { query } = this.$route

    this.federatedId = query.federated_id
    this.checkFederatedId()
    this.loggedIn = isUserLoggedIn()
  },
  methods: {
    checkFederatedId() {
      this.$http
        .get(`/api/auth/validate-federated-id/${this.federatedId}`)
        .then(response => {
          if (response.data.success) {
            localStorage.setItem('FEDERATION_INFO', JSON.stringify(response.data))
            this.$store.commit('auth/UPDATE_FEDERATION_INFO', response.data)
            this.validFederatedId = true
          } else {
            this.validFederatedId = false
          }
        })
        .catch(error => {
          this.validFederatedId = false
        })
    },
  },
}
</script>

<style lang="scss">
</style>
